body {
  font-family: 'Open Sans', Arial, sans-serif;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.bigger-font {
  font-size: 120%;
}

.size-3XS {
  zoom: 60%;
}

.size-2XS {
  zoom: 68%;
}

.size-XS {
  zoom: 77%;
}

.size-S {
  zoom: 88%;
}

.size-M {
  zoom: 100%;
}

.size-L {
  zoom: 108%;
}

.size-XL {
  zoom: 117%;
}

.size-2XL {
  zoom: 126%;
}

.size-3XL {
  zoom: 136%;
}

.App {
  overflow: hidden;
}

.HeaderBarSpacer {
  padding: 52px 0 0 0;
}

.Body {
  padding: 0;
}

.underline {
  text-decoration: underline;
}

/*
 * Coloring USER NAMES in one of:
 * - conflicts in queue
 * - conflicts in current paper
 * - users table
 */

.admin-user {
  color: blue;
}

.current-admin-user {
  color: #8dcee6;
}

.other-room {
  color: gray;
}

.bold-user {
  font-weight: bold;
}

/*
 * LOGINS
 */

.LoginPage {
  /* see https://supertokens.com/blog/building-a-login-screen-with-react-and-bootstrap# */
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.LoginPage .Auth-form {
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
  padding-top: 30px;
  width: 420px;
}

.LoginPage .Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.LoginPage .Auth-form-title {
  color: rgb(34, 34, 34);
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 1em;
  text-align: center;
}

.HeaderBar .RightSideNav {
  padding-right: 100px;
  position: fixed;
  right: 0;
}

/*
 * FLASHER
 */

.Flasher {
  background-color: white; /* fallback color */
  background-color: rgba(0, 0, 0, 0); /* fully transparent */
  border: none;
  /* this is a transparent sidebar on which notifications are rendered */
  padding: 50px 0 0 30px;
  pointer-events: none; /* allow clicks to pass through */
  transition: none;
}

.Flasher .vstack {
  /* container for notifications */
  height: 100%;
  overflow-x: hidden;
  padding: 10px;
}

.Flasher .vstack .filler {
  /* filler space when aligning notifications to bottom of page */
  flex-grow: 1; /* 0 for notifications in top-right corner, 1 for bottom-right */
}

@keyframes FlasherSlideInAnimation {
  from {
    left: 400px;
  }
  to {
    left: 0;
  }
}

@keyframes FlasherSlideOutAnimation {
  from {
    left: 0;
  }
  to {
    left: 400px;
  }
}

.FlasherAlert {
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-name: FlasherSlideInAnimation;
  box-shadow: 0 0 15px #aaa;
  margin-bottom: 0;
  pointer-events: all;
}

.FlasherAlertClosed {
  animation-fill-mode: forwards;
  animation-name: FlasherSlideOutAnimation;
}

.Body .left-panel,
.Body .right-panel {
  overflow-y: auto;
  padding-bottom: 100px;
  padding-top: 15px;
}

.Body .queue-message,
.Body .paper-message {
  border: 1px dashed purple;
  border-radius: 6px;
  color: purple;
  font-size: 20px;
  font-style: italic;
  margin: 30px;
  padding: 20px;
  text-align: center;
}

/*
 * QUEUE Panel
 */

/* gap below room menu applies for both admin and others */
.Body .room-choice-menu {
  padding-bottom: 0;
  padding-left: 12px;
}

.AdminQueueControls .btn-light {
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
}

.AdminQueueControls .arrow-btn {
  color: #777;
}

.AdminQueueControls .btn-light:hover {
  background: lightyellow;
  border: 1px solid black;
}

.AdminQueueControls .btn-light:disabled {
  border: 1px dashed gray;
  color: #777;
}

.Queue .q-show-checks {
  margin-left: auto;
}

.Queue .q-list-container {
  padding: 0;
}

.Queue ul {
  list-style: none;
  padding: 0;
}

.Queue ul li {
  overflow: hidden;
  padding: 5px;
}

.Queue .q-entry {
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  cursor: pointer;
  padding: 10px 5px;
}

.Queue .q-entry.past-entry {
  border-bottom: 0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
}

.Queue .q-entry.Current {
  border: 1px solid black;
}

.Queue .q-entry.odd-entry {
  background-color: #eee;
}

.Queue .q-entry.last-entry {
  border-bottom: 1px solid black;
}

.Queue .q-symbol {
  display: inline-block;
  font-size: 20px;
  margin-left: auto;
  padding-left: 15px;
}

.Queue .q-title {
  font-weight: bold;
}

.Queue .queue-hidden-for-non {
  border: 1px dashed purple;
  border-radius: 5px;
  color: purple;
  font-style: italic;
  font-weight: bold;
  margin: 15px;
  padding: 8px;
}

/*
 * PAPER Tab
*/

.Paper .paper-timer {
  background-color: #dadada;
  border-radius: 5px;
  color: rgb(32, 31, 96);
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 0 8px;
  position: absolute;
  right: 16px;
  text-align: left;
  top: 72px;
}

.Paper .paper-par-header {
  font-weight: bold;
  padding-right: 8px;
}

/*
 * GRID
 */

/* float this dropdown to the right */
.GridModeDropdown {
  margin-left: auto;
}

.GridBlock {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(auto-fit, 50px);
  text-align: center;
}

.GridBlock .grid-item {
  border: 1px solid #444;
  border-radius: 4px;
}

/* no longer used
.GridBlock .sticky-border {
  border: 2.5px solid black;
  border-radius: 8px;
}
*/

.GridBlock .faded-grid {
  opacity: 0.2;
}

.GridTab .grid-progress-bar {
  border: 1px solid #444;
  height: 28px;
}

/* thin line between stacked elements in progress bar */
.GridTab .grid-item .progress-bar {
  border-right: 1px solid black;
}

/* remove thin line from rightmost items in progress bar */
.GridTab .Tabled.progress-bar,
.GridTab .Conflict.progress-bar {
  border-right: 0;
}

/* thick line between different sections of progress bar */
.GridTab .Ready.progress-bar,
.GridTab .Conflict.progress-bar {
  border-left: 5px solid black;
}

/* Appears to no longer be used.
.GridTab .below-grid {
  align-items: start;
} */

.GridTab .total-count {
  margin-left: auto;
}

/*
 * Set Queue panel
 */

.SetQueue .q-message-input {
  width: 400px;
}

.SetQueue .gui-filters {
  align-items: flex-start;
}

.SetQueue .score-box {
  width: 50px;
}

.SetQueue .text-filter-input {
  margin-bottom: 20px;
  width: 550px;
}

.SetQueue .dropdown-item {
  background-color: rgb(39, 87, 195);
  background-color: rgba(39, 87, 195, 0.1);
}

.SetQueue .dropdown-item:hover {
  background-color: rgb(39, 87, 195);
  color: white;
}

/*
 * PREFS
 */

/* this is the hstack for a legend that is not currently picked */
.ColorLegend .unselected-color {
  border: 1px solid white;
  padding: 3px 8px;
}

/* this is the hstack for a legend that is currently picked */
.ColorLegend .selected-color {
  border: 1px solid black;
  border-radius: 6px;
  padding: 3px 8px;
}

.ColorLegend .rectangle,
.ChooseStatusDropdown .rectangle {
  border: 1px solid black;
  border-radius: 4px;
  display: inline-block;
  height: 15px;
  margin-right: 0.1em;
  width: 15px;
}

.ChooseStatusDropdown {
  border: 1px solid black;
  border-radius: 4px;
  height: 42px; /* force same height as neighboring buttons */
}

.ChooseStatusDropdown:hover {
  background: lightyellow;
}

/* this gets rid of the triangle on the dropdown, which was broken.
 * but we replace it using the "extra" feature.
 */
.ChooseStatusDropdown .dropdown-toggle::after {
  display: none;
}

.ChooseStatusDropdown .pull-down {
  margin-top: -5px;
}

.PreferencesPage .favorites-input {
  width: 550px;
}

.FavoritePreferences {
  margin-bottom: 50px;
}

.sticky-popover {
  background-color: darkgray;
  color: white;
}

/*
 * Guide Page
 */

.LoadingIframe iframe {
  height: 100vh;
  width: 100%;
}

/* .LoadingIframe h1 {
  padding: 40px;
} */

.App:has(.LoadingIframe) {
  background-color: #f0f0f0;
}

/* Bar SEPARATOR between Grid Sections */
/* https://stackoverflow.com/questions/2812770/add-centered-text-to-the-middle-of-a-horizontal-rule */

.GridBar {
  display: flex;
  align-items: center;
  text-align: center;
}

.GridBar::before,
.GridBar::after {
  content: '';
  flex: 1;
  border-bottom: 1px dashed #000;
}

.GridBar:not(:empty)::before {
  margin-right: 0.25em;
}

.GridBar:not(:empty)::after {
  margin-left: 0.25em;
}

.GridBar .barText {
  font-weight: bold;
}

.ChangePasswordPage .select-user-dropdown {
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
